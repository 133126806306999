import React from "react";
import styles from './GetStarted.module.scss';
import banner from '../../../../assets/backgrounds/descGetStart.png';
import bannerTb from '../../../../assets/backgrounds/tabGetStart.png';
import bannerMb from '../../../../assets/backgrounds/MobGetStart.png';
import {useAppSelector} from "../../../../hooks/redux";
import {getBlogs, getWebsiteName} from "../../../../store/selectors";

const GetStarted = ({title, btnText}) => {
    const webName = useAppSelector(getWebsiteName);

    return (
        <div className={styles.getStarted}>
            <img
                src={window.innerWidth >= 1024 ? banner : window.innerWidth < 1024 && window.innerWidth >= 768 ? bannerTb : bannerMb}
                alt=""/>
            <div className={styles.getStarted__info}>
                <p className={styles.getStarted__subTitle}>{webName}{title}</p>
                <button className={styles.getStarted__action}>
                    <span>{btnText}</span>
                </button>
            </div>
        </div>
    )
}

export default GetStarted;