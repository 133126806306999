import { baseURL } from "../settings/settings";

const fetchWithErrorHandling = async (url, options = {}) => {
    try {
        const response = await fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            ...options,
        });

        // if (!response.ok) {
        //     console.error(`Error: ${response.status} ${response.statusText}`);
        //     return response.json();
        // }

        return await response.json();
    } catch (error) {
        console.error("Network error:", error);
        return null;
    }
};

export const getUserInfo = async () => {
    const url = `${baseURL}user/profile/info`;
    return await fetchWithErrorHandling(url);
};

export const setUserTheme = async (theme) => {
    const url = `${baseURL}user/profile/change/theme?theme=${theme}`;
    return await fetchWithErrorHandling(url);
};

export const setUserLanguage = async (language) => {
    const url = `${baseURL}user/profile/change/language?language=${language}`;
    return await fetchWithErrorHandling(url);
};
