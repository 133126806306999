import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    saveSwap: 0,
    isAuth: false,
    isDarkTheme: true,
    event: null,
    isVerifyPopup: false,
    profile: {
        userName: '',
        userEmail: '',
        isEmailConfirmed: false,
        isPremium: false,
        userPhoto: null,
        id: null,
        twoFactor: false,
        kycVerified: false,
        lastActivity: null,
    },
    totalBalance: {
        coinBalance: 0,
        coinName: '',
        type: '',
        usdBalance: 0,
        chartFirstName: '',
        chartFirstValue: 0,
        chartSecondName: '',
        chartSecondValue: 0,
        chartOthersValue: 100,
    },
};

export const accountSlice = createSlice({
    name: "accountPage",
    initialState,
    reducers: {
        setSaveSwap: (state, { payload }) => {
            state.saveSwap = payload;
        },
        setTheme: (state, { payload }) => {
            state.isDarkTheme = payload;
        },
        setAuth: (state, { payload }) => {
            state.isAuth = payload;
        },
        setEvent: (state, { payload }) => {
            state.event = payload;
        },
        setIsVerifyPopup: (state, { payload }) => {
            state.isVerifyPopup = payload;
        },
        setProfile: (state, { payload }) => {
            state.profile = {
                ...state.profile,
                userName: payload.username || state.profile.userName,
                userEmail: payload.email || state.profile.userEmail,
                isEmailConfirmed: payload.email_confirmed || state.profile.isEmailConfirmed,
                isPremium: payload.premium || state.profile.isPremium,
                userPhoto: payload.profile_photo || state.profile.userPhoto,
                id: payload.id || state.profile.id,
                twoFactor: payload.twoFA || state.profile.twoFactor,
                kycVerified: payload.kycVerified || state.profile.kycVerified,
                lastActivity: payload.lastActivity || state.profile.lastActivity,
            };
        },
        change2FA: (state, { payload }) => {
            state.profile.twoFactor = payload;
        },
        updateProfileName: (state, { payload }) => {
            state.profile.userName = payload;
        },
        setBalance: (state, { payload }) => {
            const firstValue = Number(payload.allocations?.firstValue || 0);
            const secondValue = Number(payload.allocations?.secondValue || 0);
            state.totalBalance = {
                coinBalance: payload.coin_balance || 0,
                coinName: payload.coin_name || '',
                type: payload.type || '',
                usdBalance: payload.usd_balance || 0,
                chartFirstName: payload.allocations?.firstName || '',
                chartFirstValue: firstValue,
                chartSecondName: payload.allocations?.secondName || '',
                chartSecondValue: secondValue,
                chartOthersValue: firstValue + secondValue >= 100 ? 0 : 100 - (firstValue + secondValue),
            };
        },
    },
});

export default accountSlice.reducer;

export const {
    setSaveSwap,
    setTheme,
    setAuth,
    setProfile,
    setBalance,
    setIsVerifyPopup,
    setEvent,
    updateProfileName,
    change2FA,
} = accountSlice.actions;
