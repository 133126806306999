import React, { useState, useCallback } from "react";
import userNoPhoto from "../../../../assets/icons/UserNoPhoto.svg";
import userNoPhotoDark from "../../../../assets/icons/UserNoPhotoDark.svg";
import styles from "./SettAccount.module.scss";
import { Skills } from "../../../Common/Skills/Skills";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { getIsDarkTheme, getIsVerifyPopup, getProfileInfo, getText } from "../../../../store/selectors";
import { generateCode, sendNewEmail, setNewName } from "../../../../api/api";
import CodeBox from "./CodeBox";
import { setEvent, setIsVerifyPopup, setProfile, updateProfileName } from "../../../../store/account-slice";
import VerifyPopup from "../../../Common/VerifyPopup/VerifyPopup";
import { Preloader } from "../../../Common/Preloader/Preloader";
import { getUserInfo } from "../../../../api/profileApi";

const SettAccount = ({ text }) => {
    const dispatch = useAppDispatch();
    const { notification } = useAppSelector(getText);
    const isDark = useAppSelector(getIsDarkTheme);
    const profile = useAppSelector(getProfileInfo);
    const is2FAVerify = useAppSelector(getIsVerifyPopup);

    const [state, setState] = useState({
        name: "",
        email: "",
        code: "",
        showCode: false,
        isLoading: false,
    });

    const { name, email, code, showCode, isLoading } = state;

    const updateState = (updates) => setState((prev) => ({ ...prev, ...updates }));

    const refreshProfileData = useCallback(() => {
        getUserInfo().then((result) => {
            if (result?.username) {
                dispatch(setProfile(result));
                dispatch(setEvent({ text: notification.mailChanged, IsCompleted: true }));
                updateState({ email: "", code: "", showCode: false, isLoading: false });
            } else {
                updateState({ isLoading: false });
            }
        });
    }, [dispatch, notification.mailChanged]);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email.toLowerCase());
    };

    const onSave = () => {
        if (name.length > 5) {
            setNewName(name).then((response) => {
                if (response?.status === "success") {
                    dispatch(updateProfileName(name));
                    dispatch(setEvent({ text: notification.usernameChanged, IsCompleted: true }));
                    updateState({ name: "" });
                } else {
                    dispatch(setEvent({ text: notification.invalidUsername, IsCompleted: false }));
                }
            });
        }

        if (validateEmail(email) && !showCode) {
            if (profile?.twoFactor) {
                dispatch(setIsVerifyPopup(true));
            } else {
                generateCode().then((response) => {
                    if (response?.status === "success") {
                        updateState({ showCode: true });
                    }
                });
            }
        }

        if (validateEmail(email) && showCode && code.length === 4) {
            updateState({ isLoading: true });
            sendNewEmail(code, email).then((result) => {
                if (result?.status === "success") {
                    refreshProfileData();
                } else {
                    dispatch(setEvent({ text: notification.errorMail, IsCompleted: false }));
                    updateState({ isLoading: false });
                }
            });
        }
    };

    const onSend = (verificationCode) => {
        updateState({ isLoading: true });
        generateCode(verificationCode).then((response) => {
            if (response?.status === "success") {
                updateState({ showCode: true, isLoading: false });
            } else {
                dispatch(setEvent({ text: notification.invalidCode, IsCompleted: false }));
                updateState({ isLoading: false });
            }
        });
    };

    return (
        <div className={styles.settAccount}>
            <div className={styles.authBox}>
                <div
                    className={styles.usePhoto}
                    style={isDark ? { border: "1px solid #fff" } : { border: "1px solid #C9CEDE" }}
                >
                    <img src={isDark ? userNoPhoto : userNoPhotoDark} alt="User Photo" />
                </div>
                <span>{profile.userName}</span>
            </div>
            <div className={`${styles.settAccount__skills} skill-set`}>
                <Skills profile={profile} />
            </div>
            <form className={styles.form}>
                <input
                    className="customField"
                    placeholder={text.newNamePlaceholder}
                    value={name}
                    onChange={(e) => updateState({ name: e.target.value })}
                    type="text"
                />
                <input
                    className="customField"
                    placeholder={text.newEmailPlaceholder}
                    value={email}
                    onChange={(e) => updateState({ email: e.target.value })}
                    type="email"
                />
                {showCode && <CodeBox code={code} setCode={(value) => updateState({ code: value })} text={text} />}
            </form>
            <div className={styles.action}>
                <button
                    type="submit"
                    className="button"
                    onClick={onSave}
                    disabled={name.length < 5 && email.length < 5}
                >
                    <span>{showCode ? text.confirm : text.save}</span>
                </button>
            </div>
            {is2FAVerify && <VerifyPopup onSend={onSend} />}
            {isLoading && (
                <div className="loadingLocal" style={{ position: "fixed" }}>
                    <Preloader />
                </div>
            )}
        </div>
    );
};

export default SettAccount;
