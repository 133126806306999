import React, {useState} from "react";
import styles from './SpotTransfer.module.scss';
import close from '../../../../../assets/icons/close.svg'
import closeDark from '../../../../../assets/icons/closeDark.svg'
import change from '../../../../../assets/icons/spotTransferChange.svg'
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {getIsDarkTheme, getText} from "../../../../../store/selectors";
import SubNotification from "../../../../Common/SubNotification/SubNotification";
import {postMarginBorrow, postMarginRepay, postMarginTransfer} from "../../../../../api/api";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {setEvent} from "../../../../../store/account-slice";
import {getUserBalances} from "../../../../../api/balancesApi";


const SpotTransfer = ({setPopupType, pair, balance, secondBalance, text, popupType, setBalance, setSecondBalance,
                          borrowSettings, marginBalance = null}) => {
    const dispatch = useAppDispatch();
    const {notification} = useAppSelector(getText);
    const isDark = useAppSelector(getIsDarkTheme);
    const [amount, setAmount] = useState('');
    const [settingsChooser, setSettingsChooser] = useState({
        isFromSpot: true,
        isBaseCoin: true,
        isLoading: false,
        isError: false
    });

    const refreshBalance = () => {
        settingsChooser.isBaseCoin
            ? getUserBalances(pair.base).then((response) => {
                if (response) {
                    setBalance(response)
                }
            })
            : getUserBalances(pair.quote).then((response) => {
                if (response) {
                    setSecondBalance(response);
                }
            })
    }

    const onEvent = (response) => {
        if (response && response.detail === 'success') {
            dispatch(setEvent({text: notification.transactionSuccess, IsCompleted: true}));
            refreshBalance();
            setTimeout(() => {
                setPopupType(null)
            }, 800)

        } else {
            dispatch(setEvent({text: notification.transactionFailed, IsCompleted: false}));
        }
    }

    const onChangeLoading = (value, response = null) => {
        setSettingsChooser({
                               isFromSpot: settingsChooser.isFromSpot,
                               isBaseCoin: settingsChooser.isBaseCoin,
                               isLoading: value,
                               isError: settingsChooser.isError});
        if (response) {
            onEvent(response);
        }
    }

    const onChangeError = (value) => {
        setSettingsChooser({
            isFromSpot: settingsChooser.isFromSpot,
            isBaseCoin: settingsChooser.isBaseCoin,
            isLoading: settingsChooser.isLoading,
            isError: value})
    }

    const onConfirm = () => {
        onChangeLoading(true, null)
        if (popupType === 'transfer') {
            if (pair && pair.value && settingsChooser.isFromSpot) {
                postMarginTransfer(pair.value, settingsChooser.isBaseCoin ? pair.base : pair.quote, amount, true).then((response) => {
                    onChangeLoading(false, response)
                })
            } else if (pair && pair.value && !settingsChooser.isFromSpot) {
                postMarginTransfer(pair.value, settingsChooser.isBaseCoin ? pair.base : pair.quote, amount).then((response) => {
                    onChangeLoading(false, response)
                })
            }
        } else if (popupType === 'borrow' && pair && pair.value) {
            postMarginBorrow(pair.value, settingsChooser.isBaseCoin ? pair.base : pair.quote, amount).then((result) => {
                onChangeLoading(false, result)
            })
        } else if (popupType === 'repay' && pair && pair.value) {
            postMarginRepay(pair.value, settingsChooser.isBaseCoin ? pair.base : pair.quote, amount).then((result) => {
                onChangeLoading(false, result)
            })
        }
    }

    const handleChange = (value) => {
        if (popupType === 'transfer') {
            if (value === '') {
                onChangeError(false);
                setAmount(value);
            } else if (settingsChooser.isBaseCoin && value > balance.balance) {
                onChangeError(true);
                setAmount(value);
            } else if (!settingsChooser.isBaseCoin && value > secondBalance) {
                onChangeError(true);
                setAmount(value);
            } else {
                onChangeError(false);
                setAmount(value);
            }
        } else if (popupType === 'borrow') {
            if (value === '') {
                onChangeError(false);
                setAmount(value);
            } else if (settingsChooser.isBaseCoin && (value > borrowSettings[0].available || value < borrowSettings[0].minimum)) {
                onChangeError(true);
                setAmount(value);
            } else if (!settingsChooser.isBaseCoin && (value > borrowSettings[1].available || value < borrowSettings[1].minimum)) {
                onChangeError(true);
                setAmount(value);
            } else {
                onChangeError(false);
                setAmount(value);
            }
        } else if (popupType === 'repay') {
            if (value === '') {
                onChangeError(false);
                setAmount(value);
            } else if (settingsChooser.isBaseCoin && value > marginBalance[0].loanAmount) {
                onChangeError(true);
                setAmount(value);
            } else if (!settingsChooser.isBaseCoin && value > marginBalance[1].loanAmount) {
                onChangeError(true);
                setAmount(value);
            } else {
                onChangeError(false);
                setAmount(value);
            }
        }
    }

    return (
        <div className={styles.spotTransferWrapper}>
            <div className={styles.spotTransfer}
                 style={isDark ? {backgroundColor: '#171E33'} : {backgroundColor: '#fff'}}>
                <div className={styles.spotTransfer__header}>
                    {popupType === 'transfer' && (
                        <h3>{text.transfer}</h3>
                    )}
                    {popupType === 'borrow' && (
                        <h3>{text.borrow}</h3>
                    )}
                    {popupType === 'repay' && (
                        <h3>{text.repay}</h3>
                    )}
                    <button onClick={() => setPopupType(null)}>
                        <img src={isDark ? close : closeDark} alt=""/>
                    </button>
                </div>
                <div className={styles.content}>
                    {popupType === 'transfer' && (
                        <div className={styles.changerFields}>
                            <span>{text.from}</span>
                            {settingsChooser.isFromSpot
                                ?
                                <p style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>Spot</p>
                                :
                                <p style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>{pair.base}/{pair.quote} Margin</p>}
                            <div className={styles.changerFields__btn}>
                                <span>{text.to}</span>
                                <button onClick={() => setSettingsChooser({
                                    isFromSpot: !settingsChooser.isFromSpot,
                                    isBaseCoin: settingsChooser.isBaseCoin,
                                    isLoading: settingsChooser.isLoading,
                                    isError: settingsChooser.isError})}>
                                    <img src={change} alt=""/>
                                </button>
                            </div>
                            {settingsChooser.isFromSpot
                                ?
                                <p style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>{pair.base}/{pair.quote} Margin</p>
                                :
                                <p style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>Spot</p>
                            }
                        </div>
                    )}
                    {(popupType === 'borrow' || popupType === 'repay') && (
                        <div className={styles.changerFields}>
                            <p style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>{pair.base}/{pair.quote} Margin</p>
                        </div>
                    )}
                    <div className={styles.coinChanger}>
                        <button
                            style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}
                            onClick={() => {
                                setSettingsChooser({
                                    isFromSpot: settingsChooser.isFromSpot,
                                    isBaseCoin: true,
                                    isLoading: settingsChooser.isLoading,
                                    isError: settingsChooser.isError})
                                setAmount('');
                            }}>
                            <span className={styles.coinChanger__circle}
                                  style={settingsChooser.isBaseCoin ? {backgroundColor: '#2359B8'} : null}/>
                            <span className={styles.coinChanger__coin}>{pair.base}</span>
                        </button>
                        <button
                            style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}
                            onClick={() => {
                                setSettingsChooser({
                                    isFromSpot: settingsChooser.isFromSpot,
                                    isBaseCoin: false,
                                    isLoading: settingsChooser.isLoading,
                                    isError: settingsChooser.isError})
                                setAmount('');
                            }}>
                            <span className={styles.coinChanger__circle}
                                  style={settingsChooser.isBaseCoin ? null : {backgroundColor: '#2359B8'}}/>
                            <span className={styles.coinChanger__coin}>{pair.quote}</span>
                        </button>
                    </div>
                    <div className={settingsChooser.isError ? styles.fieldBoxError : styles.fieldBox}
                         style={isDark ? {border: '1px solid rgba(255, 255, 255, 0.5)'} : {border: '1px solid #C9CEDE80'}}>
                        <input className={styles.field} type="number" inputMode="decimal" value={amount}
                               onChange={(e) => handleChange(e.target.value)}
                               placeholder={`${popupType === 'transfer' ? 'Transfer' : popupType === 'borrow'
                                   ? 'Borrow' : popupType === 'repay' ? 'Repay' : null} amount`}/>
                        <div className={styles.allBox}
                             style={isDark ? {backgroundColor: '#1C243D'} : {backgroundColor: '#E4E8EF'}}>
                            <button style={isDark ? {backgroundColor: '#333F61'} : {backgroundColor: '#485A8E'}}
                                    onClick={() => settingsChooser.isBaseCoin ? setAmount(balance.balance) : setAmount(secondBalance)}>
                                <span style={{color: '#fff'}}>All</span>
                            </button>
                            <span>USDT</span>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <button onClick={() => setShowTransfer(false)} className={styles.actions__cancel}>
                            <span>{text.cancel}</span>
                        </button>
                        <button disabled={Number(amount) === 0 || amount === '' || settingsChooser.isError} className={styles.actions__confirm}
                                onClick={() => onConfirm()}
                                style={isDark ? {backgroundColor: '#2359B8'} : {backgroundColor: '#2359B8'}}>
                            <span style={{color: '#fff'}}>{text.confirm}</span>
                        </button>
                    </div>
                    {popupType === 'transfer' && (
                        <SubNotification
                            text={`Available ${settingsChooser.isBaseCoin ? balance.balance : secondBalance}`}
                        />
                    )}
                    {popupType === 'borrow' && borrowSettings && (
                        <div className={styles.borrowNotification}>
                            <SubNotification
                                text={`Min: ${settingsChooser.isBaseCoin ? borrowSettings[0].minimum : borrowSettings[1].minimum} \n 
                            Available: ${settingsChooser.isBaseCoin ? borrowSettings[0].available : borrowSettings[1].available}`}
                            />
                        </div>
                    )}
                    {popupType === 'repay' && borrowSettings && (
                        <div className={styles.borrowNotification}>
                            {/*<SubNotification*/}
                            {/*    text={`Total loan: ${settingsChooser.isBaseCoin ? marginBalance[0].loanAmount + marginBalance[0].interest : marginBalance[1].loanAmount + marginBalance[1].interest} \n */}
                            {/*Amount: ${settingsChooser.isBaseCoin ? marginBalance[0].amount - (marginBalance[0].loanAmount + marginBalance[0].interest) : marginBalance[1].amount - (marginBalance[0].loanAmount + marginBalance[0].interest)}`}*/}
                            {/*/>*/}
                            <SubNotification
                                text={`Total loan: ${settingsChooser.isBaseCoin ? (marginBalance[0].loanAmount + marginBalance[0].interest) * -1 : (marginBalance[1].loanAmount + marginBalance[1].interest) * -1} \n 
                            Available: ${settingsChooser.isBaseCoin ? marginBalance[0].amount - (marginBalance[0].loanAmount + marginBalance[0].interest) : marginBalance[1].amount - (marginBalance[0].loanAmount + marginBalance[0].interest)}`}
                            />
                        </div>
                    )}
                    {settingsChooser.isLoading && (
                        <div className='loadingLocal' style={{position: 'fixed'}}>
                            <Preloader/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SpotTransfer;